import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateCampaign from "../../../datahooks/useUpdateCampaign";
import useTargetsToken from "../../../datahooks/useTargetsToken";
import { Target } from "../../../types";

const wsBaseUrl = import.meta.env.VITE_CORE_WS_URL;

export default function useTargetsProgress() {
  const [socket, setSocket] = useState(null);
  const { targetsToken } = useTargetsToken();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignId, accountId } = useCampaignContext();

  const queryClient = useQueryClient();
  const { campaignsKeys } = getQueryKeys(workspaceId);
  const { updateCachedCampaign } = useUpdateCampaign();

  const url = `${wsBaseUrl}accounts/${accountId}/campaigns/${campaignId}/targets?authorization=${targetsToken}`;

  const connect = () => {
    const websocket = new WebSocket(url);

    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      switch (message.type) {
        case "people":
          if (
            queryClient.getQueryData(
              campaignsKeys.targets(accountId, campaignId),
            )
          ) {
            queryClient.setQueryData(
              campaignsKeys.targets(accountId, campaignId),
              (prevTargets: Target[]) => {
                const urnSet = new Set(prevTargets.map((target) => target.urn));
                const newData = message.data.filter(
                  (newTarget: Target) => !urnSet.has(newTarget.urn),
                );
                const newTargets = [...prevTargets, ...newData];

                updateCachedCampaign(accountId, campaignId, (draftCampaign) => {
                  draftCampaign.target_count = newTargets.length;
                });

                return newTargets;
              },
            );
          }
          break;
        case "status":
          queryClient.setQueryData(
            campaignsKeys.targetingStats(accountId, campaignId),
            {
              progress: message.progress,
              blocked: message.blocked,
              connectOptimization: message.connectOptimization,
              headless: message.headless,
              inmailOptimization: message.inmailOptimization,
              locked: message.locked,
              valid: message.valid,
            },
          );
          break;
        default:
          break;
      }
    };

    setSocket(websocket);
  };

  const reconnectSocket = () => {
    if (socket) {
      socket.close();
    }
    connect();
  };

  useEffect(() => {
    if (targetsToken) {
      connect();
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [targetsToken]);

  return { reconnectSocket };
}
