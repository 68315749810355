import {
  BookOpenText,
  Briefcase,
  Chat,
  Envelope,
  Factory,
  GraduationCap,
  IdentificationBadge,
  LinkSimple,
  MapPin,
  TextAlignLeft,
  Translate,
  UsersThree,
  type Icon,
} from "@phosphor-icons/react";

import {
  KeywordModifiers,
  KeywordTypes,
  MetricsParameters,
  ProfileSearchParams,
  CampaignOutreachType,
  CampaignMetrics,
} from "common/types";
import { TagVariantType } from "common/components/ui/Tag";

import { CampaignFlowType, TargetState } from "./types";

export const keywordModifierOptions: Record<
  KeywordModifiers,
  { tagVariant: TagVariantType; additionalClasses: string }
> = {
  required: {
    tagVariant: "success",
    additionalClasses: "hover:bg-green-200 data-[state=open]:bg-green-200",
  },
  excluded: {
    tagVariant: "danger",
    additionalClasses: "hover:bg-red-200 data-[state=open]:bg-red-200",
  },
  optional: {
    tagVariant: "secondary",
    additionalClasses: "hover:bg-purple-200 data-[state=open]:bg-purple-200",
  },
};

export const keywordOptions: Record<
  KeywordTypes,
  { label: string; icon: Icon }
> = {
  general: {
    label: "General",
    icon: TextAlignLeft,
  },
  company: {
    label: "Company",
    icon: Briefcase,
  },
  school: {
    label: "School",
    icon: GraduationCap,
  },
  title: {
    label: "Title",
    icon: IdentificationBadge,
  },
};

export const profileSearchParamsIcons: Record<keyof ProfileSearchParams, Icon> =
  {
    keywords: TextAlignLeft,
    locations: MapPin,
    industries: Factory,
    current_companies: Briefcase,
    past_companies: Briefcase,
    followers_of: UsersThree,
    schools: GraduationCap,
    languages: Translate,
    skills: BookOpenText,
  } as const;

export const profileSearchOptions: Record<
  | "companies"
  | "locations"
  | "followers"
  | "schools"
  | "industries"
  | "skills"
  | "languages",
  {
    icon: Icon;
    label: string;
    searchParamKey?: keyof Omit<
      ProfileSearchParams,
      "keywords" | "current_companies" | "past_companies"
    >;
    keywordsKey?: KeywordTypes;
  }
> = {
  locations: {
    label: "Locations",
    icon: MapPin,
    searchParamKey: "locations",
  },
  industries: {
    label: "Industries",
    icon: Factory,
    searchParamKey: "industries",
  },
  companies: {
    label: "Companies",
    icon: Briefcase,
    keywordsKey: "company",
  },
  followers: {
    label: "Followers of",
    icon: UsersThree,
    searchParamKey: "followers_of",
  },
  schools: {
    label: "Schools",
    icon: GraduationCap,
    searchParamKey: "schools",
    keywordsKey: "school",
  },
  skills: { label: "Skills", icon: BookOpenText, searchParamKey: "skills" },
  languages: {
    label: "Profile Languages",
    icon: Translate,
    searchParamKey: "languages",
  },
};

export const sequenceDelayOptions = (isTabletOrDesktop?: boolean) => {
  const dayOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i,
    label: `${i < 10 ? "0" : ""}${i}${isTabletOrDesktop ? "" : ` Day${i === 1 ? "" : "s"}`}`,
  }));

  const hourOptions = Array.from({ length: 25 }, (_, i) => ({
    value: i,
    label: `${i < 10 ? "0" : ""}${i}${isTabletOrDesktop ? "" : ` Hour${i === 1 ? "" : "s"}`}`,
  }));

  return { dayOptions, hourOptions };
};

export const targetStates: {
  states: TargetState[];
  tagVariant: TagVariantType;
  label: string;
}[] = [
  {
    states: ["init"],
    tagVariant: "quaternary",
    label: "Initialized",
  },
  {
    states: ["view", "view_branch"],
    tagVariant: "magenta",
    label: "Viewing Profile",
  },
  {
    states: ["connect", "withdraw"],
    tagVariant: "orange",
    label: "Sending Connection",
  },
  {
    states: ["inmail"],
    tagVariant: "secondary",
    label: "Sending InMail",
  },
  {
    states: ["message", "message_request"],
    tagVariant: "info",
    label: "Sending Message",
  },
  {
    states: ["done"],
    tagVariant: "success",
    label: "Completed",
  },
  {
    states: ["cancelled"],
    tagVariant: "danger",
    label: "Cancelled",
  },
] as const;

export const flowTypeOptions: Record<
  CampaignFlowType,
  {
    tagVariant: TagVariantType;
    icon: Icon;
    classNames: string;
  }
> = {
  CONNECT_OPTIMIZATION: {
    tagVariant: "orange",
    icon: LinkSimple,
    classNames: "bg-orange-100 text-orange-600",
  },
  INMAIL_OPTIMIZATION: {
    tagVariant: "secondary",
    icon: Envelope,
    classNames: "bg-purple-100 text-purple-600",
  },
  PRIMARY_CONNECT: {
    tagVariant: "orange",
    icon: LinkSimple,
    classNames: "bg-orange-100 text-orange-600",
  },
  PRIMARY_MESSAGE: {
    tagVariant: "info",
    icon: Chat,
    classNames: "bg-blue-100 text-blue-500",
  },
};

export const campaignOutreachOptions: Record<
  CampaignOutreachType,
  {
    icon: Icon;
    label: string;
    tagVariant: TagVariantType;
    hiddenMetricsColumns: (keyof CampaignMetrics)[];
    getSuccessString: (metrics: CampaignMetrics) => string;
  }
> = {
  connect: {
    icon: LinkSimple,
    label: "Connect",
    tagVariant: "orange",
    hiddenMetricsColumns: ["message_requests"],
    getSuccessString: (metrics) =>
      `${metrics.accepted_connections}/${metrics.sent_connections}`,
  },
  message: {
    icon: Chat,
    label: "Message",
    tagVariant: "info",
    hiddenMetricsColumns: ["sent_connections", "accepted_connections"],
    getSuccessString: (metrics) =>
      `${metrics.sent_messages}/${metrics.replies}`,
  },
  inmail: {
    icon: Envelope,
    label: "InMail",
    tagVariant: "secondary",
    hiddenMetricsColumns: [
      "views",
      "sent_connections",
      "sent_messages",
      "accepted_connections",
      "message_requests",
    ],
    getSuccessString: (metrics) => `${metrics.sent_inmails}/${metrics.replies}`,
  },
};

export const statsTimeframeOptions: {
  label: string;
  selectionLabel: string;
  parameters: MetricsParameters;
}[] = [
  {
    label: "30-days",
    selectionLabel: "30-day statistics",
    parameters: {
      bucketSize: "1 day",
      timestamps: {
        from: Date.now() - 29 * 24 * 60 * 60 * 1000,
        to: Date.now(),
      },
    },
  },
  {
    label: "7-days",
    selectionLabel: "7-day statistics",
    parameters: {
      bucketSize: "1 day",
      timestamps: {
        from: Date.now() - 6 * 24 * 60 * 60 * 1000,
        to: Date.now(),
      },
    },
  },
  {
    label: "Last 24 hours",
    selectionLabel: "Last 24 hours' Statistics",
    parameters: {
      bucketSize: "1 hour",
      timestamps: {
        from: Date.now() - 24 * 60 * 60 * 1000,
        to: Date.now(),
      },
    },
  },
];
