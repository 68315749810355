import React from "react";
import { VirtuosoGrid } from "react-virtuoso";

import { LinkedInProfile } from "common/types";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import { Target } from "../../../../types";
import TargetProfile from "./TargetProfile";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

interface AudiencePreviewProps {
  profiles: Target[];
  selectedProfiles: LinkedInProfile[];
  toggleSelection: (profile: LinkedInProfile) => void;
  searchValue: string;
}

export default function AudiencePreview({
  profiles,
  selectedProfiles,
  toggleSelection,
  searchValue,
}: AudiencePreviewProps) {
  if (!profiles.length)
    return (
      <EmptyPlaceholder
        imageSrc={NoResultsImage}
        title="No results found"
        subtitle={`No results found for "${searchValue}"`}
      />
    );

  return (
    <VirtuosoGrid
      totalCount={profiles.length}
      useWindowScroll
      listClassName="grid md:grid-cols-2 lg:grid-cols-3 gap-3"
      /* eslint-disable-next-line react/no-unstable-nested-components */
      itemContent={(index) => {
        const result = profiles[index];
        const { id } = result;
        const isSelected = !!selectedProfiles.find(
          (profile) => profile.id === id,
        );

        return (
          <TargetProfile
            result={profiles[index]}
            isSelected={isSelected}
            toggleSelection={toggleSelection}
          />
        );
      }}
    />
  );
}
