import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { HTTPError, del, get, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Targeting } from "../types";

async function getTargetingRequest(
  accountId: string,
  campaignId: string,
): Promise<Targeting> {
  return (await get(`accounts/${accountId}/campaigns/${campaignId}/targeting`))
    .targeting;
}

async function setTargetingRequest(
  accountId: string,
  campaignId: string,
  targeting: Targeting,
): Promise<Targeting> {
  return (
    await post(`accounts/${accountId}/campaigns/${campaignId}/targeting`, {
      ...targeting,
    })
  ).targeting;
}

async function deleteTargetingRequest(accountId: string, campaignId: string) {
  await del(`accounts/${accountId}/campaigns/${campaignId}/targeting`);
}

export default function useTargeting() {
  const { campaignId, accountId } = useCampaignContext();
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: targeting, isLoading: isLoadingTargeting } = useQuery({
    queryKey: campaignsKeys.targeting(accountId, campaignId),
    queryFn: () => getTargetingRequest(accountId, campaignId),
  });

  const { mutateAsync: setTargeting, isPending: isSettingTargeting } =
    useMutation({
      mutationFn: ({ campaignTargeting }: { campaignTargeting: Targeting }) =>
        setTargetingRequest(accountId, campaignId, campaignTargeting),
      onSuccess: (newTargeting) => {
        queryClient.setQueryData(
          campaignsKeys.targeting(accountId, campaignId),
          newTargeting,
        );
      },
    });

  const { mutateAsync: removeTargeting, isPending: isRemovingTargeting } =
    useMutation({
      mutationFn: () => deleteTargetingRequest(accountId, campaignId),
      onSuccess: () => {
        queryClient.setQueryData(
          campaignsKeys.targeting(accountId, campaignId),
          null,
        );
        queryClient.setQueryData(
          campaignsKeys.targets(accountId, campaignId),
          [],
        );
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  return {
    targeting,
    isLoadingTargeting,
    setTargeting,
    isSettingTargeting,
    removeTargeting,
    isRemovingTargeting,
  };
}
