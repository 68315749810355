import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { get, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateCampaign from "./useUpdateCampaign";
import { Target } from "../types";

async function getTargetsRequest(
  accountId: string,
  campaignId: string,
): Promise<Target[]> {
  return (await get(`accounts/${accountId}/campaigns/${campaignId}/targets`))
    .targets;
}

async function removeTargetsRequest(
  accountId: string,
  campaignId: string,
  targetUrns: string[],
): Promise<string[]> {
  return (
    await post(`accounts/${accountId}/campaigns/${campaignId}/targets/delete`, {
      target_urns: targetUrns,
    })
  ).targets;
}

export default function useTargets() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignId, accountId } = useCampaignContext();

  const { updateCachedCampaign } = useUpdateCampaign();
  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: targets, isLoading: isLoadingTargets } = useQuery({
    queryKey: campaignsKeys.targets(accountId, campaignId),
    queryFn: () => getTargetsRequest(accountId, campaignId),
  });

  const { mutateAsync: removeTargets, isPending: isRemovingTargets } =
    useMutation({
      mutationFn: ({ targetUrns }: { targetUrns: string[] }) =>
        removeTargetsRequest(accountId, campaignId, targetUrns),
      onSuccess: (targetUrns) => {
        queryClient.setQueryData(
          campaignsKeys.targets(accountId, campaignId),
          (prevTargets: Target[]) => {
            const newTargets = prevTargets.filter(
              (prevTarget) => !targetUrns.includes(prevTarget.urn),
            );

            updateCachedCampaign(accountId, campaignId, (draftCampaign) => {
              draftCampaign.target_count = newTargets.length;
            });

            return newTargets;
          },
        );
      },
    });

  return {
    targets,
    isLoadingTargets,
    removeTargets,
    isRemovingTargets,
  };
}
