import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { ConversationMessage } from "../types";

const itemsPerPage = 30;

async function getMessages(
  accountId: string,
  createdBefore: number | "now",
  conversationUrn: string,
): Promise<ConversationMessage[]> {
  const searchParams = new URLSearchParams();
  searchParams.append("count", itemsPerPage.toString());
  searchParams.append(
    "created_before",
    (createdBefore === "now" ? Date.now() : createdBefore).toString(),
  );

  const { messages } = await get(
    `accounts/${accountId}/conversations/${conversationUrn}?${searchParams.toString()}`,
  );
  return messages;
}
export default function useConversationMessages({
  accountId,
  conversationUrn,
  unreadCount,
  lastMessageCreatedAt,
}: {
  accountId: string;
  conversationUrn: string;
  unreadCount: number;
  lastMessageCreatedAt: number;
}) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { conversationsKeys } = getQueryKeys(workspaceId);

  const {
    isLoading: isLoadingMessages,
    data: messages,
    fetchNextPage: loadNextMessages,
    hasNextPage: hasMoreMessages,
    refetch,
  } = useInfiniteQuery<ConversationMessage[], number | "now">({
    queryKey: conversationsKeys.messages(conversationUrn),
    queryFn: ({ pageParam }) =>
      getMessages(accountId, pageParam as number | "now", conversationUrn),
    enabled: !!conversationUrn,
    initialPageParam: "now",
    getNextPageParam: (lastPage) => {
      if (lastPage.length) {
        return lastPage[lastPage.length - 1].created_at;
      }
      return undefined;
    },
  });

  const allMessages = messages?.pages.flat() as ConversationMessage[];

  useEffect(() => {
    if (unreadCount) {
      refetch();
    }
  }, [unreadCount]);

  useEffect(() => {
    if (
      lastMessageCreatedAt &&
      allMessages &&
      allMessages.length > 0 &&
      allMessages[0].created_at !== lastMessageCreatedAt
    ) {
      // Check if the last message's timestamp is newer before refetching
      if (lastMessageCreatedAt > allMessages[0].created_at) {
        refetch();
      }
    }
  }, [lastMessageCreatedAt, allMessages]);

  return {
    messages: allMessages,
    isLoadingMessages,
    loadNextMessages,
    hasMoreMessages,
  };
}
