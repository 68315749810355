import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Lock } from "@phosphor-icons/react";
import { UseFormReturn } from "react-hook-form";
import clsx from "clsx";

import useGTM from "common/hooks/useGTM";
import { WorkspaceAccount } from "common/types";
import { HTTPError } from "common/helpers/HTTP";
import { Button } from "common/components/ui/Button";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import { LoginSchemaType } from "common/schemas";
import RenderIf from "common/components/RenderIf";
import useDebounce from "common/hooks/useDebounce";
import useCountries from "common/datahooks/useCountries";

import useWorkspaceAccountMutations from "../../datahooks/useWorkspaceAccountMutations";
import LinkedInAccountCard from "./LinkedInAccountCard";
import ProxySelect from "../ProxySelect";
import ExtensionLogin from "./ExtensionLogin";

interface LoginProps {
  formReturn: UseFormReturn<LoginSchemaType>;
  account: WorkspaceAccount | undefined;
  onSuccess: () => void;
  goBack: () => void;
  setStep: (step: "thiswasme" | "pin") => void;
}

export default function Login({
  formReturn: {
    watch,
    setError,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  },
  account,
  onSuccess,
  setStep,
  goBack,
}: LoginProps) {
  const { sendEvent } = useGTM();
  const { login, isLoggingIn } = useWorkspaceAccountMutations();
  const shouldShowDelayMessage = useDebounce(isLoggingIn, 7000);
  const { suggestedCountryCode, countryCodes } = useCountries();

  const currentProxyLocation = watch("proxyLocation");
  
  // Set the proxy location to the suggested country if it's not set
  useEffect(() => {
    if (
      !currentProxyLocation &&
      suggestedCountryCode &&
      countryCodes?.includes(suggestedCountryCode)
    ) {
      setValue("proxyLocation", suggestedCountryCode);
    }
  }, [suggestedCountryCode, countryCodes]);

  function onSubmit(loginData: LoginSchemaType) {
    login({ loginData })
      .then((response) => {
        if (response.loginResult === "PASS") {
          // Log to Sentry if full_name is missing
          if (!response.profile.full_name) {
            Sentry.captureMessage("Login - missing full_name");
            Sentry.setExtra("response_json", JSON.stringify(response));
          }

          onSuccess();
        } else if (response.loginResult === "CHALLENGE") {
          const { challengeType } = response;
          if (
            [
              "EmailPinChallenge",
              "SMSPinChallenge",
              "AuthenticatorAppChallenge",
            ].includes(challengeType)
          ) {
            Sentry.captureMessage("Login challenge");
            setStep("pin");
          } else if (challengeType === "CaptchaChallenge") {
            Sentry.captureMessage("Login - Captcha challenge");
            setError("password", {
              message: "Captcha challenge",
            });
          } else if (challengeType === "AppChallenge") {
            setStep("thiswasme");
          }
        }
      })
      .catch((err) => {
        Sentry.setExtra("error", err);
        Sentry.setExtra("error_json", JSON.stringify(err));
        Sentry.captureMessage(
          `Login error${err instanceof HTTPError ? ` - ${err.type}` : ""}`,
        );

        sendEvent("connect_linkedin_account_fail");

        let errorMessage: string;
        if (
          err instanceof HTTPError &&
          err.serverMessage ===
            "logging into this account is forbidden by linkedin"
        ) {
          errorMessage = "Logging into this account is forbidden by LinkedIn";
        } else if (err.code === 409) {
          // this happens when the account is already connected to another workspace
          errorMessage = "Account already connected to another workspace";
        } else if (err.code === 401) {
          errorMessage = "Invalid email or password";
        } else {
          errorMessage = "Error connecting account";
        }

        setError("password", {
          message: errorMessage,
        });
      });
  }

  return (
    <form className="flex flex-col pt-4" onSubmit={handleSubmit(onSubmit)}>
      <RenderIf condition={!!account}>
        <LinkedInAccountCard account={account} />
      </RenderIf>

      <RenderIf condition={!account?.email}>
        <>
          <Label
            className={clsx("mb-1 ml-0.5", account && "mt-8 md:mt-4")}
            htmlFor="email"
          >
            Email address
          </Label>
          <Input
            id="email"
            variant="lg"
            placeholder="Email"
            error={errors.email?.message}
            {...register("email")}
          />
        </>
      </RenderIf>

      <Label
        className={clsx(
          "mb-1 ml-0.5 md:mt-4",
          account?.email ? "mt-8" : "mt-4",
        )}
        htmlFor="password"
      >
        Password
      </Label>
      <Input
        id="password"
        variant="lg"
        error={errors.password?.message}
        type="password"
        {...register("password")}
        placeholder="Enter password here"
      />

      <span className="mb-1 mt-4 text-black-700">Proxy (login location)</span>
      <ProxySelect
        countryCode={currentProxyLocation}
        onChange={(countryCode) => {
          setValue("proxyLocation", countryCode, {
            shouldValidate: true,
          });
        }}
      />
      {errors?.proxyLocation && (
        <span className="mt-1 text-caption-12-regular text-red-500">
          {errors.proxyLocation.message}
        </span>
      )}

      {/* Long Loading text */}
      <RenderIf condition={isLoggingIn && shouldShowDelayMessage}>
        <span className="mt-1 text-caption-12-regular text-black-500 animate-in fade-in-0">
          The login process is taking some time, please do not close or refresh
          this page
        </span>
      </RenderIf>

      {account ? (
        <>
          <Button
            className="mb-3 mt-8 md:mt-12"
            variant="primary-black"
            type="submit"
            isLoading={isLoggingIn}
          >
            Connect Account
          </Button>
          <div className="flex items-center justify-center gap-x-0.5">
            <Lock weight="fill" className="size-4 text-black-700" />
            <span className="text-caption-12-regular text-black-700">
              Secured with TLS 1.3 encryption
            </span>
          </div>
        </>
      ) : (
        <div className="flex gap-x-4 pt-8">
          <Button
            variant="secondary-black"
            size="lg"
            className="flex-1"
            disabled={isLoggingIn}
            type="button"
            onClick={goBack}
          >
            Back
          </Button>

          <Button
            variant="primary-black"
            size="lg"
            className="flex-1"
            type="submit"
            isLoading={isLoggingIn}
          >
            Add
          </Button>
        </div>
      )}

      <ExtensionLogin account={account} onBack={goBack} />
    </form>
  );
}
