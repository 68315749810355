import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";

import { Campaign } from "common/types";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import useLicenseMutations from "common/datahooks/useLicenseMutations";

import useCampaignMutations from "../datahooks/useCampaignMutations";
import { CampaignNameSchema } from "../schemas";
import { DetailedCampaign } from "../types";

export default function useCampaignActions(
  campaign: DetailedCampaign | Campaign,
  onClose: () => void,
) {
  const { id, owner, state } = campaign;

  const { relativeNavigate } = useWorkspaceNavigate();

  const {
    deleteCampaign,
    updateCampaign,
    isDeletingCampaign,
    isUpdatingCampaign,
  } = useCampaignMutations();

  const { assignLicense, isAssigningLicense } = useLicenseMutations();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { name: campaign.name },
    resolver: valibotResolver(CampaignNameSchema),
  });

  function updateCampaignState() {
    updateCampaign({
      campaignId: id,
      accountId: owner,
      updates: { state: state === "ACTIVE" ? "PAUSED" : "ACTIVE" },
    }).then(onClose);
  }

  function removeCampaign() {
    deleteCampaign({ campaignId: id, accountId: owner }).then(() => {
      relativeNavigate("campaigns");
      onClose();
    });
  }

  function updateCampaignName({ name }: { name: string }) {
    updateCampaign({
      updates: { name },
      campaignId: id,
      accountId: owner,
    }).then(() => {
      reset({ name });
      onClose();
    });
  }

  function handleEditName() {
    handleSubmit(updateCampaignName)();
  }

  function assignLicenseToOwner() {
    assignLicense({ accountId: owner });
  }

  return {
    updateCampaignState,
    removeCampaign,
    handleEditName,
    assignLicenseToOwner,
    isAssigningLicense,
    isUpdatingCampaign,
    isDeletingCampaign,
    // Form returns
    reset,
    errors,
    register,
    watch,
  };
}
