import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { get, post } from "common/helpers/HTTP";

type ProxyResponse = {
  "location.countryISO": {
    $eq: string;
  };
  "provider.name": {
    $eq: string;
  } | null;
};

async function getAccountProxyRequest(
  accountId: string,
): Promise<ProxyResponse> {
  return (await get(`accounts/${accountId}/proxy`)).config;
}

async function setProxyRequest(
  accountId: string,
  countryCode: string,
): Promise<ProxyResponse> {
  return (
    await post(`accounts/${accountId}/proxy`, {
      proxy_config: {
        "location.countryISO": {
          $eq: countryCode,
        },
        "provider.name": {
          $eq: "nord",
        },
      },
    })
  ).config;
}

export default function useAccountProxy(accountId: string) {
  const queryClient = useQueryClient();

  const { data: proxy, isLoading: isLoadingProxy } = useQuery({
    queryKey: ["proxy", accountId],
    queryFn: () => getAccountProxyRequest(accountId),
  });

  const { mutateAsync: setProxy, isPending: isSettingProxy } = useMutation({
    mutationFn: (countryCode: string) =>
      setProxyRequest(accountId, countryCode),
    onMutate: (newProxyCountryCode) => {
      queryClient.setQueryData(["proxy", accountId], newProxyCountryCode);
    },
    onSuccess: () => {
      toast.success("Proxy updated successfully");
    },
    onError: () => {
      toast.error("Error updating proxy");
    },
  });

  const proxyCountryCode = proxy?.["location.countryISO"]?.$eq;

  return { proxyCountryCode, isLoadingProxy, setProxy, isSettingProxy };
}
