import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { getCountryName } from "common/helpers/utils";

async function getCountryCodesRequests(): Promise<string[]> {
  return (await get("proxy/countries")).countries;
}

async function getSuggestedCountryCodeRequest(): Promise<string> {
  return (await get("proxy/suggestion")).country_code;
}

export default function useCountries() {
  const { data: countryCodes, isLoading: isLoadingCountryCodes } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountryCodesRequests,
  });

  const {
    data: suggestedCountryCode,
    isLoading: isLoadingSuggestedCountryCode,
  } = useQuery({
    queryKey: ["countrySuggestion"],
    queryFn: getSuggestedCountryCodeRequest,
  });

  const isLoadingCountries =
    isLoadingCountryCodes || isLoadingSuggestedCountryCode;

  // Sort country codes alphabetically by country name
  if (countryCodes) {
    countryCodes.sort((a, b) => {
      const nameA = getCountryName(a);
      const nameB = getCountryName(b);
      return nameA.localeCompare(nameB, "en", { ignorePunctuation: true });
    });
  }

  return {
    countryCodes,
    suggestedCountryCode,
    isLoadingCountries,
  };
}
