import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useCampaignContext } from "common/helpers/CampaignContext";

import { LinkedInGroup } from "../types";

async function getUserGroupsRequest(
  accountId: string,
): Promise<LinkedInGroup[]> {
  return (await get(`accounts/${accountId}/groups`)).groups;
}

async function getLinkedinGroupRequest(
  accountId: string,
  groupId: string,
): Promise<LinkedInGroup> {
  return (await get(`accounts/${accountId}/groups/${groupId}`)).group;
}

export default function useLinkedinGroups({
  groupId,
  disableQuery,
}: {
  groupId?: string;
  disableQuery?: boolean;
} = {}) {
  const { accountId } = useCampaignContext();

  const { data: linkedinGroups, isLoading: isLoadingLinkedinGroups } = useQuery(
    {
      queryKey: ["linkedinGroups", accountId],
      queryFn: () => getUserGroupsRequest(accountId),
      enabled: !groupId && !disableQuery,
    },
  );

  const { data: linkedinGroup, isLoading: isLoadingLinkedinGroup } = useQuery({
    queryKey: ["linkedinGroups", accountId, groupId],
    queryFn: () => getLinkedinGroupRequest(accountId, groupId),
    enabled: !!groupId,
  });

  return {
    linkedinGroups,
    isLoadingLinkedinGroups,
    linkedinGroup,
    isLoadingLinkedinGroup,
  };
}
