import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useCampaignContext } from "common/helpers/CampaignContext";

import { LinkedinPost } from "../types";

async function getPostDetails(
  accountId: string,
  postId: string,
): Promise<LinkedinPost> {
  return (await get(`accounts/${accountId}/posts/${postId}`)).post;
}

export default function useLinkedinPost({ postId }: { postId: string }) {
  const { accountId } = useCampaignContext();

  const {
    data: linkedinPost,
    isLoading: isLoadingLinkedinPost,
    isError: isLinkedinPostError,
  } = useQuery({
    queryKey: ["posts", postId],
    queryFn: () => getPostDetails(accountId, postId),
  });

  return {
    linkedinPost,
    isLinkedinPostError,
    isLoadingLinkedinPost,
  };
}
