import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import RenderIf from "common/components/RenderIf";
import Loader from "common/components/Loader";

import { DetailedCampaign } from "../../types";
import {
  getSortedAndFilteredFlows,
  isValidSchedule,
  validateFlows,
} from "../../utils";
import Targeting from "./Targeting";
import useTargeting from "../../datahooks/useTargeting";
import useTargetingStats from "../../datahooks/useTargetingStats";
import Audience from "./Audience";
import CampaignSchedule from "./CampaignSchedule";
import Review from "./Review";
import CampaignFlows from "./CampaignFlows";
import AudienceInfo from "./AudienceInfo";
import TargetingPreview from "../TargetingPreview";

interface CreateCampaignProps {
  campaign: DetailedCampaign;
}

export default function CreateCampaign({ campaign }: CreateCampaignProps) {
  const { pathname } = useLocation();
  const { targeting, isLoadingTargeting } = useTargeting();
  const { targetingStats, isLoadingTargetingStats } = useTargetingStats();

  const sortedAndFilteredFlows = getSortedAndFilteredFlows(
    campaign.flows,
    campaign.inmail_optimization,
  );

  const { timezone, ...schedule } = campaign.schedule;

  const STEPS = [
    {
      path: "targeting",
      component: (
        <Targeting targeting={targeting} campaignType={campaign.type} />
      ),
    },
    {
      path: "audience",
      component: (
        <Audience
          targetingStats={targetingStats}
          isLoadingTargetingStats={isLoadingTargetingStats}
          audienceSize={campaign.audience_size}
        />
      ),
    },
    {
      path: "flows",
      component: (
        <CampaignFlows
          campaign={campaign}
          targetingStats={targetingStats}
          isLoadingTargetingStats={isLoadingTargetingStats}
        />
      ),
    },
    {
      path: "schedule",
      component: (
        <CampaignSchedule
          flows={sortedAndFilteredFlows}
          campaignSchedule={campaign.schedule}
        />
      ),
    },
    {
      path: "review",
      component: <Review flows={sortedAndFilteredFlows} schedule={schedule} />,
    },
  ];

  const { isFlowListValid } = validateFlows(
    campaign.flows,
    campaign.inmail_optimization,
  );

  if (isLoadingTargeting) return <Loader />;

  const selectedIndex = STEPS.findIndex(({ path }) => pathname.endsWith(path));

  if (selectedIndex === -1) return <Navigate to={STEPS[0].path} replace />;
  if (selectedIndex > 0 && !targeting) {
    return <Navigate to="targeting" />;
  }
  if (selectedIndex === 0 && !!targeting) {
    return <Navigate to="audience" />;
  }
  if (selectedIndex > 2 && !isFlowListValid) {
    return <Navigate to="flows" />;
  }
  if (selectedIndex > 3 && !isValidSchedule(schedule)) {
    return <Navigate to="schedule" />;
  }

  return (
    <div className="mb-32 flex flex-1 flex-col md:mb-28">
      <RenderIf condition={selectedIndex > 0}>
        <TargetingPreview
          campaignType={campaign.type}
          isEditDisabled={
            targetingStats ? targetingStats.progress !== 100 : true
          }
          shouldShowEditButton
        />
      </RenderIf>
      <RenderIf condition={selectedIndex > 1}>
        <AudienceInfo audienceSize={campaign.audience_size} />
      </RenderIf>
      <Routes>
        {STEPS.map(({ path, component }) => (
          <Route key={path} path={path} element={component} />
        ))}
      </Routes>
    </div>
  );
}
