import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateConversation from "./useUpdateConversation";
import { ConversationMessage, ConversationParticipant } from "../types";

async function sendMessageRequest(
  accountId: string,
  message: string,
  recipients: ConversationParticipant[],
) {
  const {
    conversation_urn: conversationUrn,
    message_urn: messageUrn,
    created_at: createdAt,
  } = await post(`accounts/${accountId}/conversations`, {
    message: message.trim(),
    recipients: recipients.map(({ id }) => id),
  });
  return {
    message,
    createdAt,
    recipients,
    conversationUrn,
    messageUrn,
  };
}
async function resetUnreadMessagesRequest(
  accountId: string,
  conversationUrn: string,
) {
  await post(`accounts/${accountId}/conversations/${conversationUrn}/read`);
  return conversationUrn;
}

export default function useConversationUpdates() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { updateConversation, addMessage } = useUpdateConversation();
  const { leadsKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: sendMessage, isPending: isSendingMessage } = useMutation(
    {
      mutationFn: ({
        accountId,
        message,
        recipients,
      }: {
        accountId: string;
        message: string;
        recipients: ConversationParticipant[];
      }) => sendMessageRequest(accountId, message, recipients),
      onSuccess: (
        {
          message,
          createdAt,
          recipients,
          conversationUrn: returnedConversationUrn,
          messageUrn,
        },
        { accountId },
      ) => {
        const newMessage: ConversationMessage = {
          urn: messageUrn,
          body: message,
          created_at: createdAt,
          sender: {
            id: accountId,
          },
          type: "MEMBER_TO_MEMBER",
        };

        updateConversation(
          returnedConversationUrn,
          (draftConversation) => {
            draftConversation.last_message = newMessage;
            // this is for conversations that are new in the cache
            if (!draftConversation.participants) {
              draftConversation.participants = recipients;
            }
            if (!draftConversation.last_activity_at) {
              draftConversation.last_activity_at = createdAt;
            }
          },
          accountId,
        );
        addMessage(returnedConversationUrn, newMessage);

        // for conversations with leads that don't have a conversationUrn yet
        if (
          recipients.length === 1 &&
          !queryClient.getQueryData(leadsKeys.conversationUrn(recipients[0].id))
        ) {
          queryClient.setQueryData(
            leadsKeys.conversationUrn(recipients[0].id),
            returnedConversationUrn,
          );
        }
      },
      onError: () => {
        toast.error("Unable to send a message, please try again later");
      },
    },
  );

  const { mutateAsync: resetUnreadMessages } = useMutation({
    mutationFn: ({
      accountId,
      conversationUrn,
    }: {
      accountId: string;
      conversationUrn: string;
    }) => resetUnreadMessagesRequest(accountId, conversationUrn),
    onSuccess: (conversationUrn) => {
      updateConversation(conversationUrn, (draftConversation) => {
        draftConversation.unread_count = 0;
      });
    },
  });

  return { sendMessage, isSendingMessage, resetUnreadMessages };
}
