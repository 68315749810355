import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { patch } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { CampaignFlow, FlowInmailTemplate, FlowNoteTemplate } from "../types";
import useUpdateCampaign from "./useUpdateCampaign";

async function updateFlowRequest(
  accountId: string,
  campaignId: string,
  flowId: string,
  updates: Partial<CampaignFlow>,
): Promise<CampaignFlow> {
  return (
    await patch(
      `accounts/${accountId}/campaigns/${campaignId}/flows/${flowId}`,
      updates,
    )
  ).flow;
}

export default function useCampaignFlow() {
  const queryClient = useQueryClient();
  const { campaignId, accountId } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);
  const { updateCachedCampaign } = useUpdateCampaign();

  const { mutateAsync: updateFlow, isPending: isUpdatingFlow } = useMutation({
    mutationFn: ({
      flowId,
      updates,
    }: {
      flowId: string;
      updates: Partial<CampaignFlow>;
    }) => updateFlowRequest(accountId, campaignId, flowId, updates),
    onMutate: async ({ flowId, updates }) => {
      await queryClient.cancelQueries({
        queryKey: campaignsKeys.details(accountId, campaignId),
      });

      const previousCampaign = queryClient.getQueryData(
        campaignsKeys.details(accountId, campaignId),
      );

      updateCachedCampaign(accountId, campaignId, (draftCampaign) => {
        const flowIndex = draftCampaign.flows.findIndex(
          (flow) => flow.id === flowId,
        );

        draftCampaign.flows[flowIndex] = {
          ...draftCampaign.flows[flowIndex],
          ...updates,
        };
      });

      return { previousCampaign };
    },
    onError: (_, __, { previousCampaign }) => {
      queryClient.setQueryData(
        campaignsKeys.details(accountId, campaignId),
        previousCampaign,
      );

      toast.error(`Error updating flow`);
    },
  });

  function addEmptyFlowTemplate(flowId: string) {
    updateCachedCampaign(accountId, campaignId, (draftCampaign) => {
      const flowIndex = draftCampaign.flows.findIndex(
        (flow) => flow.id === flowId,
      );

      const noteTemplate: FlowNoteTemplate = {
        ai: false,
        edited: false,
        message: "",
        original_id: null,
        type: "NOTE_TEMPLATE",
      };

      const inmailTemplate: FlowInmailTemplate = {
        ...noteTemplate,
        subject: "",
        type: "INMAIL_TEMPLATE",
      };

      draftCampaign.flows[flowIndex].template =
        draftCampaign.flows[flowIndex].type === "PRIMARY_CONNECT"
          ? noteTemplate
          : inmailTemplate;
    });
  }

  return { addEmptyFlowTemplate, updateFlow, isUpdatingFlow };
}
