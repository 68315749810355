import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { get } from "../helpers/HTTP";
import { RecentLead } from "../types";
import getQueryKeys from "./getQueryKeys";

async function getRecentLeads(
  workspaceId: string,
  campaignId: string,
  flowId: string,
  accountIds: string[],
): Promise<RecentLead[]> {
  const searchParams = new URLSearchParams();

  if (campaignId) {
    searchParams.append("campaign_id", campaignId);
  }

  if (flowId) {
    searchParams.append("flow_id", flowId);
  }

  if (accountIds.length) {
    searchParams.append("account_ids", JSON.stringify(accountIds));
  }

  return (
    await get(
      `workspaces/${workspaceId}/leads/recent?${searchParams.toString()}`,
    )
  ).leads;
}
export default function useRecentLeads({
  flowId,
  campaignId,
  accountIds,
}: {
  flowId: string;
  campaignId: string;
  accountIds: string[];
}) {
  const { id: workspaceId, accounts } = useSelectedWorkspaceContext();

  const { leadsKeys } = getQueryKeys(workspaceId);

  const {
    data: recentLeads,
    isLoading: isLoadingRecentLeads,
    error: recentLeadsError,
    refetch: refetchRecentLeads,
  } = useQuery({
    queryKey: leadsKeys.recent(accountIds, campaignId, flowId),
    queryFn: () => getRecentLeads(workspaceId, campaignId, flowId, accountIds),
    enabled: !!accounts.length,
  });

  return {
    recentLeads,
    isLoadingRecentLeads,
    recentLeadsError,
    refetchRecentLeads,
  };
}
