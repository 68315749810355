import { useQuery } from "@tanstack/react-query";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { get } from "common/helpers/HTTP";

async function getTargetsToken(
  accountId: string,
  campaignId: string,
): Promise<string> {
  return (
    await get(`accounts/${accountId}/campaigns/${campaignId}/targets/token`)
  ).token;
}

export default function useTargetsToken() {
  const { campaignId, accountId } = useCampaignContext();

  const { data: targetsToken } = useQuery({
    queryKey: ["targets_token", campaignId],
    queryFn: () => getTargetsToken(accountId, campaignId),
  });

  return { targetsToken };
}
