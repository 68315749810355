import React from "react";
import { Export } from "@phosphor-icons/react";

import { LinkedInProfile } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

import { Target } from "../../../types";
import { exportProfilesToCSV } from "../../../utils";

interface ExportAudienceProps {
  targets: Target[];
  selectedProfiles: LinkedInProfile[];
}

export default function ExportAudience({
  targets,
  selectedProfiles,
}: ExportAudienceProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const profiles = selectedProfiles.length ? selectedProfiles : targets;

  let text = "Export ";
  if (selectedProfiles.length) {
    text += `${selectedProfiles.length}${isTabletOrDesktop ? ` Target${selectedProfiles.length > 1 ? "s" : ""}` : ""}`;
  } else {
    text += "audience";
  }

  return (
    <Button
      className="flex-1"
      variant={selectedProfiles.length ? "secondary-purple" : "secondary-black"}
      size={isTabletOrDesktop ? "lg" : "md"}
      leftIcon={
        (selectedProfiles.length || isTabletOrDesktop) && (
          <Export weight="fill" />
        )
      }
      asChild
    >
      <a href={exportProfilesToCSV(profiles)} download="LinkedInProfiles.csv">
        {text}
      </a>
    </Button>
  );
}
