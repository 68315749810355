import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

async function getConversationUrn(accountId: string, leadId: string) {
  const { conversation_urn: conversationUrn } = await get(
    `accounts/${accountId}/leads/${leadId}/conversation-urn`,
  );
  return conversationUrn;
}

export default function useLeadConversationUrn({
  leadId,
  accountId,
}: {
  leadId: string;
  accountId: string;
}) {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { leadsKeys } = getQueryKeys(workspaceId);

  const { data: leadConversationUrn } = useQuery<string>({
    queryKey: leadsKeys.conversationUrn(leadId),
    queryFn: () => getConversationUrn(accountId, leadId),
    enabled: !!leadId,
  });
  return leadConversationUrn;
}
