import { useQuery } from "@tanstack/react-query";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { get, post } from "common/helpers/HTTP";
import {
  LinkedInProfile,
  ProfileSearchParams,
  GenericSearchParam,
  SearchParamWithImage,
} from "common/types";

async function getIndustries(accountId: string, keywords: string) {
  return (
    await get(`accounts/${accountId}/search/industries?keyword=${keywords}`)
  ).industries;
}
async function getCompanies(accountId: string, keywords: string) {
  return (
    await get(`accounts/${accountId}/search/companies?keyword=${keywords}`)
  ).companies;
}
async function getLocations(accountId: string, keywords: string) {
  return (
    await get(`accounts/${accountId}/search/locations?keyword=${keywords}`)
  ).locations;
}
async function getFollowers(accountId: string, keywords: string) {
  return (
    await get(`accounts/${accountId}/search/followers?keyword=${keywords}`)
  ).profiles;
}
async function getSchools(accountId: string, keywords: string) {
  return (await get(`accounts/${accountId}/search/schools?keyword=${keywords}`))
    .schools;
}
async function getSkills(accountId: string, keywords: string) {
  return (await get(`accounts/${accountId}/search/skills?keyword=${keywords}`))
    .skills;
}
async function getLanguages(accountId: string, keywords: string) {
  return (
    await get(`accounts/${accountId}/search/languages?keyword=${keywords}`)
  ).languages;
}
async function getSuggestions(
  accountId: string,
  searchParams: Partial<ProfileSearchParams> = {},
) {
  return (await post(`accounts/${accountId}/search/suggestions`, searchParams))
    .suggestions;
}
export default function useSearchOptions({
  searchParams,
  companiesKeywords,
  locationsKeywords,
  followersKeywords,
  schoolsKeywords,
  industriesKeywords,
  skillsKeywords,
  languagesKeywords,
}: {
  searchParams?: Partial<ProfileSearchParams>;
  companiesKeywords?: string;
  locationsKeywords?: string;
  followersKeywords?: string;
  schoolsKeywords?: string;
  industriesKeywords?: string;
  skillsKeywords?: string;
  languagesKeywords?: string;
} = {}) {
  const { accountId } = useCampaignContext();

  const { data: industries, isLoading: isLoadingIndustries } = useQuery<
    GenericSearchParam[]
  >({
    queryKey: [accountId, "industries", industriesKeywords],
    queryFn: () => getIndustries(accountId, industriesKeywords),
    enabled: !!industriesKeywords,
  });

  const { data: companies, isLoading: isLoadingCompanies } = useQuery<
    SearchParamWithImage[]
  >({
    queryKey: [accountId, "companies", companiesKeywords],
    queryFn: () => getCompanies(accountId, companiesKeywords),
    enabled: !!companiesKeywords,
  });

  const { data: locations, isLoading: isLoadingLocations } = useQuery<
    GenericSearchParam[]
  >({
    queryKey: [accountId, "locations", locationsKeywords],
    queryFn: () => getLocations(accountId, locationsKeywords),
    enabled: !!locationsKeywords,
  });

  const { data: schools, isLoading: isLoadingSchools } = useQuery<
    SearchParamWithImage[]
  >({
    queryKey: [accountId, "schools", schoolsKeywords],
    queryFn: () => getSchools(accountId, schoolsKeywords),
    enabled: !!schoolsKeywords,
  });

  const { data: followers, isLoading: isLoadingFollowers } = useQuery<
    LinkedInProfile[]
  >({
    queryKey: [accountId, "followers", followersKeywords],
    queryFn: () => getFollowers(accountId, followersKeywords),
    enabled: !!followersKeywords,
  });

  const { data: skills, isLoading: isLoadingSkills } = useQuery({
    queryKey: [accountId, "skills", skillsKeywords],
    queryFn: () => getSkills(accountId, skillsKeywords),
    enabled: !!skillsKeywords,
  });

  const { data: languages, isLoading: isLoadingLanguages } = useQuery<
    GenericSearchParam[]
  >({
    queryKey: [accountId, "languages", languagesKeywords],
    queryFn: () => getLanguages(accountId, languagesKeywords),
    enabled: !!languagesKeywords,
  });

  const { data: suggestions, isLoading: isLoadingSuggestions } = useQuery({
    queryKey: [accountId, "suggestions"],
    queryFn: () => getSuggestions(accountId, searchParams),
    staleTime: Infinity,
  });

  return {
    industries,
    isLoadingIndustries,
    companies,
    isLoadingCompanies,
    locations,
    isLoadingLocations,
    followers,
    isLoadingFollowers,
    schools,
    isLoadingSchools,
    skills,
    isLoadingSkills,
    languages,
    isLoadingLanguages,
    suggestions,
    isLoadingSuggestions,
    isLoading:
      isLoadingIndustries ||
      isLoadingCompanies ||
      isLoadingLocations ||
      isLoadingFollowers ||
      isLoadingSchools ||
      isLoadingSkills ||
      isLoadingLanguages ||
      isLoadingSuggestions,
  };
}
