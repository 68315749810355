import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useCampaignContext } from "common/helpers/CampaignContext";

import { LinkedInEvent } from "../types";

async function getUserEventsRequest(
  accountId: string,
): Promise<LinkedInEvent[]> {
  return (await get(`accounts/${accountId}/events`)).events;
}

async function getLinkedinEventRequest(
  accountId: string,
  eventId: string,
): Promise<LinkedInEvent> {
  return (await get(`accounts/${accountId}/events/${eventId}`)).event;
}

export default function useLinkedinEvents({
  eventId,
  disableQuery,
}: {
  eventId?: string;
  disableQuery?: boolean;
} = {}) {
  const { accountId } = useCampaignContext();

  const { data: linkedinEvents, isLoading: isLoadingLinkedinEvents } = useQuery(
    {
      queryKey: ["linkedinEvents", accountId],
      queryFn: () => getUserEventsRequest(accountId),
      enabled: !eventId && !disableQuery,
    },
  );

  const { data: linkedinEvent, isLoading: isLoadingLinkedinEvent } = useQuery({
    queryKey: ["linkedinEvents", accountId, eventId],
    queryFn: () => getLinkedinEventRequest(accountId, eventId),
    enabled: !!eventId,
  });

  return {
    linkedinEvents,
    isLoadingLinkedinEvents,
    linkedinEvent,
    isLoadingLinkedinEvent,
  };
}
