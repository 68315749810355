import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { TargetingStats } from "../types";

async function getTargetingStats(
  accountId: string,
  campaignId: string,
): Promise<TargetingStats> {
  return (
    await get(`accounts/${accountId}/campaigns/${campaignId}/targeting/stats`)
  ).stats;
}

export default function useTargetingStats({
  disableQuery,
}: {
  disableQuery?: boolean;
} = {}) {
  const { campaignId, accountId } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: targetingStats, isLoading: isLoadingTargetingStats } = useQuery(
    {
      queryKey: campaignsKeys.targetingStats(accountId, campaignId),
      queryFn: () => getTargetingStats(accountId, campaignId),
      enabled: !disableQuery,
    },
  );

  return {
    targetingStats,
    isLoadingTargetingStats,
  };
}
