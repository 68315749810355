import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { DetailedCampaign } from "../types";

async function getCampaignRequest(accountId: string, campaignId: string) {
  return (await get(`accounts/${accountId}/campaigns/${campaignId}`)).campaign;
}
export default function useCampaignDetails(
  accountId: string,
  campaignId: string,
) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);

  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    refetch: refetchCampaign,
    error: campaignError,
  } = useQuery<DetailedCampaign>({
    queryKey: campaignsKeys.details(accountId, campaignId),
    queryFn: () => getCampaignRequest(accountId, campaignId),
    enabled: !!campaignId,
  });

  return {
    campaign,
    campaignError,
    isLoadingCampaign,
    refetchCampaign,
  };
}
