import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, del, patch, HTTPError } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { CampaignUpdates, DetailedCampaign, Target } from "../types";
import useUpdateCampaign from "./useUpdateCampaign";

type NewCampaign = Pick<
  DetailedCampaign,
  "name" | "type" | "audience_size" | "outreach_type"
> & {
  account_id: string;
};

async function createCampaignRequest(
  workspaceId: string,
  newCampaign: NewCampaign,
) {
  return (await post(`workspaces/${workspaceId}/campaigns`, newCampaign))
    .campaign;
}
async function updateCampaignRequest(
  accountId: string,
  campaignId: string,
  updates: CampaignUpdates,
) {
  await patch(`accounts/${accountId}/campaigns/${campaignId}`, updates);
  return { campaignId, updates, accountId };
}
async function deleteCampaignRequest(accountId: string, campaignId: string) {
  await del(`accounts/${accountId}/campaigns/${campaignId}`);
  return campaignId;
}

async function updateAudienceSizeRequest(
  accountId: string,
  campaignId: string,
  size: number,
) {
  const removedTargets: string[] = (
    await post(`accounts/${accountId}/campaigns/${campaignId}/audience`, {
      size,
    })
  ).removed;

  return { removedTargets, size };
}

export default function useCampaignMutations() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const {
    updateCachedCampaign,
    updateCachedCampaigns,
    updateCachedCampaignInList,
  } = useUpdateCampaign();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: createCampaign, isPending: isCreatingCampaign } =
    useMutation({
      mutationFn: (newCampaign: NewCampaign) =>
        createCampaignRequest(workspaceId, newCampaign),
    });

  const { mutateAsync: updateCampaign, isPending: isUpdatingCampaign } =
    useMutation({
      mutationFn: ({
        accountId,
        campaignId,
        updates,
      }: {
        accountId: string;
        campaignId: string;
        updates: CampaignUpdates;
      }) => updateCampaignRequest(accountId, campaignId, updates),
      onSuccess: ({ accountId, updates, campaignId }) => {
        updateCachedCampaign(accountId, campaignId, (draftCampaign) => ({
          ...draftCampaign,
          ...updates,
        }));

        updateCachedCampaignInList(campaignId, updates);
      },
    });

  const { mutateAsync: deleteCampaign, isPending: isDeletingCampaign } =
    useMutation({
      mutationFn: ({
        accountId,
        campaignId,
      }: {
        accountId: string;
        campaignId: string;
      }) => deleteCampaignRequest(accountId, campaignId),
      onSuccess: (campaignId) => {
        updateCachedCampaigns((campaigns) =>
          campaigns.filter(({ id }) => id !== campaignId),
        );
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  const { mutateAsync: updateAudienceSize, isPending: isUpdatingAudienceSize } =
    useMutation({
      mutationFn: ({
        accountId,
        campaignId,
        size,
      }: {
        accountId: string;
        campaignId: string;
        size: number;
      }) => updateAudienceSizeRequest(accountId, campaignId, size),
      onSuccess: ({ removedTargets, size }, { campaignId, accountId }) => {
        updateCachedCampaign(accountId, campaignId, (draftCampaign) => {
          draftCampaign.audience_size = size;
        });

        queryClient.setQueryData(
          campaignsKeys.targets(accountId, campaignId),
          (prevTargets: Target[]) => {
            const newTargets = prevTargets.filter(
              (prevTarget) => !removedTargets.includes(prevTarget.urn),
            );

            updateCachedCampaign(accountId, campaignId, (draftCampaign) => {
              draftCampaign.target_count = newTargets.length;
            });

            return newTargets;
          },
        );
      },
    });

  return {
    createCampaign,
    isCreatingCampaign,
    updateCampaign,
    isUpdatingCampaign,
    deleteCampaign,
    isDeletingCampaign,
    updateAudienceSize,
    isUpdatingAudienceSize,
  };
}
